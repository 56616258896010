import Amplitude from 'amplitude-js';
import { injectable } from 'inversify';

// TODO: refactor, move app to usue AmplitudeManager and cleanup old amplitude implementation

const amplitude = Amplitude.getInstance();
const API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_KEY;

export const amplitudeInit = () => {
  try {
    amplitude.init(API_KEY);
  } catch (e) {
    console.log(e);
  }
};
export const trackEvent = async (eventType: string, eventProperties?: Record<string, any>) => {
  amplitude.logEvent(eventType, eventProperties);
};

export const setUserForTracking = ({
  email,
  accountType,
  planType,
  purchaseType,
  role,
}: UserProperties) => {
  amplitude.setUserId(email);
  amplitude.setUserProperties({
    account_type: accountType,
    ...(planType && { plan_type: planType }),
    ...(purchaseType && { purchase_type: purchaseType }),
    ...(role && { role }),
  });
};

export type UserProperties = {
  companyWebsite?: string;
  businessPhone?: string;
  email: string;
  accountType: string;
  planType?: string;
  purchaseType?: string;
  role?: string;
  companyName?: string;
  companySize?: string;
};

export interface IAmplitudeManager {
  amplitude: Amplitude;
  setUserForTracking: (data: UserProperties) => void;
  init(key: string): void;
  trackEvent(eventType: string, eventProperties?: Record<string, any>): void;
}

@injectable()
export default class AmplitudeManager implements IAmplitudeManager {
  amplitude;

  constructor() {
    this.amplitude = amplitude;
    this.init(API_KEY);
  }

  trackEvent(eventType, eventProperties) {
    this.amplitude.logEvent(eventType, eventProperties);
  }

  setUserForTracking({
    email,
    accountType,
    planType,
    purchaseType,
    role,
    companyWebsite,
    companySize,
    companyName,
    businessPhone,
  }: UserProperties) {
    this.amplitude.setUserId(email);
    this.amplitude.setUserProperties({
      account_type: accountType,
      ...(planType && { plan_type: planType }),
      ...(purchaseType && { purchase_type: purchaseType }),
      ...(role && { role }),
      ...(companySize && { company_size: companySize }),
      ...(companyWebsite && { company_website: companyWebsite }),
      ...(businessPhone && { business_phone: businessPhone }),
      ...(companyName && { company_name: companyName }),
    });
  }

  init(API_KEY) {
    this.amplitude.init(API_KEY);
  }
}
