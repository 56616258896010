import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reorder } from '../../utils/array';
import remove from 'lodash/remove';
import { v4 as uuidv4 } from 'uuid';

import {
  TagBoard,
  TagBoardColumn,
  TagItem,
  mapDCItemToModel,
  DCTagBoardItem,
} from '../../api/tagsBoardAPI';
import { AppState, AppDispatch } from '../../app/store/store';
import {
  attachTagsToContact,
  removeItemFromTagBoardThunk,
  addTagThunk,
  patchTagThunk,
  addManyTags,
  patchManyTags,
  removeManyTags,
  updateTagsOrder,
} from './asyncThunks';
import { selectContactsMap } from 'slices/contactsSlice';
type SliceState = {
  loading: 'idle' | 'pending' | 'loaded';
  tagsBoard: TagBoard;
  existedContact: string;
  isBoardRow: boolean;
  shouldSync: boolean;
  syncFailed: boolean;
};
interface DCTagLink extends DCTagBoardItem {
  next_uuid: string;
}
const initialState: SliceState = {
  shouldSync: false,
  syncFailed: false,
  tagsBoard: { columns: [] },
  loading: 'idle',
  existedContact: '',
  isBoardRow: false,
};

const tagsBoardSlice = createSlice({
  name: 'tagsBoard',
  initialState: initialState,
  reducers: {
    setShouldSync(state, action: PayloadAction<boolean>) {
      state.shouldSync = action.payload;
    },
    setSyncFailed(state, action: PayloadAction<boolean>) {
      state.syncFailed = action.payload;
    },
    deleteTags(state, action: PayloadAction<string[]>) {
      state.tagsBoard.columns = state.tagsBoard.columns.filter(
        ({ id }) => !action.payload.includes(id)
      );
    },
    deleteTagLinks(state, action: PayloadAction<DCTagLink[]>) {
      action.payload.map(mapDCItemToModel).forEach((item) => {
        const tagCol = state.tagsBoard.columns.find((colItem) => colItem.id === item.tagId);
        remove(tagCol.items, (tagItem) => tagItem.id === item.id);
      });
    },
    deleteItems(
      state,
      action: PayloadAction<{
        columnUuid: string;
        itemsUuids: string[];
      }>
    ) {
      const { columnUuid, itemsUuids } = action.payload;
      const column = state.tagsBoard.columns.find(({ id }) => id === columnUuid);
      const filteredItems = column.items.filter(({ id }) => !itemsUuids.includes(id));
      column.items = filteredItems;
    },
    updateTagLinks(state, action: PayloadAction<DCTagLink[]>) {
      const tagLinksMap = state.tagsBoard.columns.reduce((acc, column) => {
        column.items.forEach((item) => {
          acc[item.id] = item;
        });
        return acc;
      }, {});

      action.payload
        .map((item) => {
          return { ...mapDCItemToModel(item), nextItemId: item.next_uuid };
        })
        .forEach((item) => {
          const existedItem = tagLinksMap[item.id];
          const { nextItemId, ...preparedNewItem } = item;

          if (existedItem) {
            if (existedItem.tagId != item.tagId) {
              //item exists and change column
              const prevColumn = state.tagsBoard.columns.find(({ id }) => id === existedItem.tagId);
              const newColumn = state.tagsBoard.columns.find(({ id }) => id === item.tagId);
              remove(prevColumn.items, (columnBoardItem) => columnBoardItem.id === existedItem.id);
              const nextBoardItemIndex = newColumn.items.findIndex(
                (columnBoardItem) => columnBoardItem.id === nextItemId
              );
              if (nextBoardItemIndex === -1) {
                newColumn.items.push(preparedNewItem);
              } else {
                newColumn.items.splice(nextBoardItemIndex, 0, preparedNewItem);
              }
            } else {
              //item exists and not change column
              const currentColumn = state.tagsBoard.columns.find(({ id }) => id === item.tagId);
              if (currentColumn) {
                const prevStateItemIndex = currentColumn.items.findIndex(
                  (item) => item.id === existedItem.id
                );
                currentColumn.items.splice(prevStateItemIndex, 1);

                const destIndex = currentColumn.items.findIndex(
                  (columnBoardItem) => columnBoardItem.id === nextItemId
                );

                if (destIndex === -1) {
                  currentColumn.items.push(item);
                } else {
                  currentColumn.items.splice(destIndex, 0, preparedNewItem);
                }
              }
            }
          } else {
            //add new item to some column

            const currentColumn = state.tagsBoard.columns.find(({ id }) => id === item.tagId);
            if (currentColumn) {
              const destIndex = currentColumn.items.findIndex(
                (columnBoardItem) => columnBoardItem.id === nextItemId
              );

              if (destIndex === -1) {
                currentColumn.items.push(item);
              } else {
                currentColumn.items.splice(destIndex, 0, preparedNewItem);
              }
            }
          }
          tagLinksMap[item.id] = item;
        });
    },
    setTags: (state, action: PayloadAction<TagBoardColumn[]>) => {
      state.tagsBoard.columns = action.payload;
    },
    setIsBoardRow(state, action) {
      state.isBoardRow = action.payload;
    },
    addItem(
      state,
      action: PayloadAction<{ destColumnId: string; destIndex: number; item: TagItem }>
    ) {
      const { item, destColumnId, destIndex } = action.payload;

      const destColumn = state.tagsBoard.columns.find((colItem) => colItem.id === destColumnId);

      const isExist = destColumn.items.find(
        (currentItem) => currentItem.itemTypeId === item.itemTypeId
      );

      if (isExist) {
        state.existedContact = item.itemTypeId;
      } else {
        destColumn.items.splice(destIndex, 0, item);
      }
    },
    moveColumn(state, action: PayloadAction<{ id: string; destIndex: number }>) {
      const {
        payload: { id, destIndex },
      } = action;

      const index = state.tagsBoard.columns.findIndex((col) => col.id === id);
      const [column] = state.tagsBoard.columns.splice(index, 1);

      state.tagsBoard.columns.splice(destIndex, 0, column);
    },
    removeItem(state, action: PayloadAction<{ columnId: string; itemId: string }>) {
      const {
        payload: { columnId, itemId },
      } = action;

      const column = state.tagsBoard.columns.find((colItem) => colItem.id === columnId);
      const itemIndex = column.items.findIndex(({ id }) => id === itemId);

      column.items.splice(itemIndex, 1);
    },
    changeItemOrder(
      state,
      action: PayloadAction<{
        columnId: string;
        itemId: string;
        index: number;
      }>
    ) {
      const { columnId, itemId, index } = action.payload;

      const column = state.tagsBoard.columns.find((colItem) => colItem.id === columnId);
      const prevItemIndex = column.items.findIndex((item) => item.id === itemId);
      const reorderedItems = reorder(column.items, prevItemIndex, index);
      column.items = reorderedItems;
    },
    moveItem(
      state,
      action: PayloadAction<{
        sourceColumnId: string;
        destColumnId: string;
        itemId: string;
        destIndex?: number;
      }>
    ) {
      const { sourceColumnId, destColumnId, itemId } = action.payload;

      const sourceColumn = state.tagsBoard.columns.find(({ id }) => id == sourceColumnId);
      const oldItem = sourceColumn.items.find(({ id }) => id === itemId);

      const updatedItem: TagItem = {
        ...oldItem,
        tagId: destColumnId,
        updatedAt: Math.floor(Date.now() / 1000),
      };
      const indexInSourceColumn = sourceColumn.items.indexOf(oldItem);
      sourceColumn.items.splice(indexInSourceColumn, 1);
      const destColumn = state.tagsBoard.columns.find(({ id }) => id == destColumnId);
      !destColumn.items.some((item) => item.id === oldItem.id) &&
        destColumn.items.push(updatedItem);
    },
    removeTag(state, action: PayloadAction<TagBoardColumn['id']>) {
      const id = action.payload;
      const index = state.tagsBoard.columns.findIndex((colItem) => colItem.id === id);

      state.tagsBoard.columns.splice(index, 1);
    },
    clearTagItems(state, action: PayloadAction<TagBoardColumn['id']>) {
      const destinationColumn = state.tagsBoard.columns.find(
        (colItem) => colItem.id === action.payload
      );

      destinationColumn.items = [];
    },
    addTag(state, action: PayloadAction<TagBoardColumn>) {
      const { payload: tag } = action;

      const isExisted = state.tagsBoard.columns.some((column) => column.title == tag.title);

      if (!isExisted) {
        state.tagsBoard.columns.push(tag);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(attachTagsToContact.fulfilled, (state, action) => {
      for (const tagItem of action.payload.updated) {
        const tagCol = state.tagsBoard.columns.find((colItem) => colItem.id === tagItem.tagId);
        tagCol.items.unshift(tagItem);
      }
    });
    builder.addCase(removeItemFromTagBoardThunk.fulfilled, (state, action) => {
      const items = action.payload;
      items.forEach(({ tagItemId, columnId }) => {
        const column = state.tagsBoard.columns.find((colItem) => colItem.id === columnId);
        const itemIndex = column.items.findIndex(({ id }) => id === tagItemId);
        column.items.splice(itemIndex, 1);
      });
    });
    builder.addCase(addTagThunk.fulfilled, (state, action) => {
      const { payload: tag } = action;

      const isExisted = state.tagsBoard?.columns.some((column) => column.title == tag.title);
      if (state.tagsBoard && !isExisted) {
        state.tagsBoard.columns.push(tag);
      } else if (!isExisted) {
        state.tagsBoard = { columns: [tag] };
      }
    });
    builder.addCase(addManyTags.fulfilled, (state, action) => {
      const { payload: tags } = action;

      state.tagsBoard.columns = state.tagsBoard.columns.concat(tags);
    });
    builder.addCase(patchManyTags.fulfilled, (state, action) => {
      const tags = action.meta.arg;

      for (const tag of tags) {
        const [id, data] = tag;

        const index = state.tagsBoard.columns.findIndex((col) => col.id === id);

        state.tagsBoard.columns[index] = {
          ...state.tagsBoard.columns[index],
          ...data,
        };
      }
    });
    builder.addCase(removeManyTags.fulfilled, (state, action) => {
      const ids = action.meta.arg;

      state.tagsBoard.columns.filter((tag) => !ids.includes(tag.id));
    });
    builder.addCase(patchTagThunk.fulfilled, (state, action) => {
      const { id, data } = action.meta.arg;

      const index = state.tagsBoard.columns.findIndex((column) => column.id == id);
      const prevTag = state.tagsBoard.columns[index];

      state.tagsBoard.columns.splice(index, 1, { ...prevTag, ...data });
    });

    builder.addCase(updateTagsOrder.fulfilled, (state, action) => {
      const { order, updatedAt } = action.meta.arg;

      const updatedColumns = order.map((id) => {
        const col = state.tagsBoard.columns.find((colItem) => colItem.id === id);

        return col;
      });

      state.tagsBoard.columns = updatedColumns;
      state.tagsBoard.updatedAt = updatedAt;
    });
  },
});

export const addItem = ({
  destColumnId,
  itemTypeId,
  destIndex,
}: {
  destColumnId: string;
  itemTypeId: string;
  destIndex?: number;
}) => {
  const item: TagItem = {
    id: uuidv4(),
    type: 'contact',
    itemTypeId,
    tagId: destColumnId,
    createdAt: Math.floor(Date.now() / 1000),
    updatedAt: Math.floor(Date.now() / 1000),
  };

  return (dispatch: AppDispatch) => {
    dispatch(actions.addItem({ destIndex, item, destColumnId }));
  };
};

export const { reducer, actions } = tagsBoardSlice;
export const {
  deleteTagLinks,
  deleteTags,
  updateTagLinks,
  moveColumn,
  setTags,
  deleteItems,
  moveItem,
  changeItemOrder,
  removeItem,
  clearTagItems,
  removeTag,
  setIsBoardRow,
} = actions;
export const selectIsBoardRow = (state: AppState) => state.tagsBoard.isBoardRow;
export const selectTags = (state: AppState) =>
  state.tagsBoard.tagsBoard?.columns.map(({ id, color, title }) => {
    return { id, color, title };
  });
export const selectAllTagsForAllContactsMap = createSelector(
  (state: AppState) => state.tagsBoard.tagsBoard,
  (state: AppState) => state.contacts.contactsArray,
  (tagsBoard, contacts) => {
    if (!tagsBoard || !contacts) return {};

    const tagsBoardMapContactId = tagsBoard.columns.reduce((acc, tagColumn) => {
      tagColumn.items.forEach((item) => {
        acc[item.itemTypeId] = [...(acc[item.itemTypeId] || []), tagColumn];
      });

      return acc;
    }, {});

    return tagsBoardMapContactId;
  }
);
export const selectContactTags = (contactId: string) =>
  createSelector(selectAllTagsForAllContactsMap, (tagContactsMap) => {
    return tagContactsMap[contactId] || [];
  });
export const selectTagsBoard = (state: AppState) => state.tagsBoard.tagsBoard;
export const selectShouldSync = (state: AppState) => state.tagsBoard.shouldSync;

export const selectTagColumnById = (state: AppState, id: string) =>
  state.tagsBoard.tagsBoard.columns.find((colItem) => colItem.id === id);

export const makeSelectTagsBoardWithVisibleContacts = createSelector(
  selectTagsBoard,
  selectContactsMap,
  (tagsBoard, contactsMap) => ({
    ...tagsBoard,
    columns: tagsBoard.columns.map((col) => ({
      ...col,
      items: col.items.filter(
        (item) => !contactsMap[item.itemTypeId]?.not_show && contactsMap[item.itemTypeId]
      ),
    })),
  })
);

export default reducer;
