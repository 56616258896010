import { injectable, inject, postConstruct } from 'inversify';
import {
  first,
  combineLatest,
  map,
  BehaviorSubject,
  switchMap,
  catchError,
  throwError,
} from 'rxjs';
import { IUserStateRepository } from '../domain/abstractions/IUserStateRepository';
import { TYPES } from '../../../app/ioc/types';
import { IPersistentRepository } from '../../../services/PersistentRepository';
import { TYPES as USER_MODULE_TYPES } from '../di/types';
import { IUserDao } from './UserDao';
import ConnectionStateTypes from 'src/enums/ConnectionStateTypes';

import { TYPES as WORKSPACES_TYPES } from 'src/features2/workspaces/di/types';
import { IWorkspacesStateRepository } from 'src/features2/workspaces/domain/abstractions/IWorkspacesStateRepository';
import { TYPES as PROFILE_TYPES } from 'src/features2/profile/di/types';
import { IProfileDao } from 'src/features2/profile/data/ProfileDao';
import IUserApiService from '../domain/abstractions/IUserApiService';
import { setDataToLocalStorage } from 'src/utils';
import ProviderInfoEntity from 'src/db/entities/providerInfo/ProviderInfoEntity';
import UserEntity from '../domain/entities/User';

@injectable()
export default class UserStateRepository implements IUserStateRepository {
  @inject(TYPES.PersistentRepository) private persistentRepository: IPersistentRepository;
  @inject(WORKSPACES_TYPES.WorkspacesStateRepository)
  private workspacesRepository: IWorkspacesStateRepository;
  private providerInfo: Omit<ProviderInfoEntity, 'profile' | 'workspaces'>;
  @inject(USER_MODULE_TYPES.UserDao) private dao: IUserDao;
  @inject(PROFILE_TYPES.ProfileDao) private profileDao: IProfileDao;
  @inject(USER_MODULE_TYPES.UserAPIService) private api: IUserApiService;
  private userSubject = new BehaviorSubject<UserEntity>(null);
  @postConstruct()
  init() {
    // workaround to resubscribe for collections (after we clean them on logout)
    this.workspacesRepository.currentWorkspaceId
      .pipe(switchMap(() => this.initCurrentUser()))
      .subscribe(this.userSubject);
  }

  initCurrentUser = () => {
    return combineLatest(
      [this.workspacesRepository.getCurrentWorkspace(), this.profileDao.getProfile()],
      (workspace, profile) => {
        if (!workspace || !profile) return null; // if user logged-out (think a better way)
        const member = workspace.members.find(({ email }) => email === profile.email);
        const userConnectionState = this.providerInfo?.connectionState;
        const isWorkspaceCollaborative =
          workspace?.members.filter((member) => member.invitationStatus === 'accepted').length > 1;
        const userIsRegistered =
          userConnectionState === ConnectionStateTypes.EXIST || isWorkspaceCollaborative;

        return {
          ...profile,
          isNew: false,
          isAdmin: member.role === 'admin' || member.role === 'owner',
          isInvitedUser: member?.role !== 'owner',
          hasApp: profile.platforms.some((platform) => platform !== 'web'),
          isRegistered: userIsRegistered,
          isOnboardingFilled:
            member?.role !== 'owner' ||
            !!(workspace?.businessPhone && workspace?.companySize && workspace?.name) ||
            !!profile.onboardingInfo,
          role: member.role,
          currentWorkspace: workspace.id,
          // isAdmin: false, // for test
        };
      }
    );
  };

  fetchUserByTempToken = (provider, token) => {
    return this.api.fetchProviderInfoByTempToken(provider, token).pipe(
      switchMap((providerInfo) => {
        const { profile, workspaces, ...restProviderInfo } = providerInfo;
        setDataToLocalStorage('user', restProviderInfo);
        this.providerInfo = restProviderInfo;
        this.workspacesRepository.putWorkspaces(workspaces);
        return this.dao.upsertProfile(profile);
      }),
      map(() => true),
      catchError((e) => throwError(e))
    );
  };

  logout = () => {
    this.persistentRepository.clear();
    return this.dao.wipeData().pipe(
      first(),
      map(() => true)
    );
  };

  getCurrentUser = () => {
    return this.userSubject;
  };

  getUser = () => {
    return this.profileDao.getProfile();
  };
}
