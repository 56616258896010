import { createAsyncThunk, createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import {
  createContactAPI,
  deleteContactAPI,
  patchContactAPI,
  patchManyContactsAPI,
} from '../../api/contactsAPI';
import { AppState } from '../../app/store/store';
import { selectUserLanguage } from '../userSlice';
import remove from 'lodash/remove';
import find from 'lodash/find';
import omit from 'lodash/omit';
import { findLastIndex } from '../../utils/array';
import { filterByAnchors, findAnchors } from './utils';
import Contact from '../../../types/contact';
import sortingService from 'src/services/data/sortingService';
import { selectCurrentWorkspaceId, selectCurrentUserMemberData } from '../workspacesSlice';

//TODO: should be removed and replaced by patchContact
export const updateContactById = createAsyncThunk(
  'contacts/updateContactById',
  async (contactId: string, thunkAPI) => {
    const state = thunkAPI.getState() as AppState;

    const currentContact = state.contacts.contactsArray.find(
      (contact) => contact.uuid == contactId
    );
    await patchContactAPI({
      uuid: contactId,
      data: {
        ...currentContact,
        updated_at: Math.floor(Date.now() / 1000),
      },
      workspaceId: currentContact.workspace_id,
    });
  }
);

class PatchRequestsManager {
  private requestsMap: Record<
    Contact['uuid'],
    {
      previousData: Partial<Contact>;
      promise: Promise<void>;
      resolver: () => void;
    }
  > = {};

  addRequest = ({
    contactId,
    dataToUpdate,
    outdatedContact,
  }: {
    contactId: Contact['uuid'];
    dataToUpdate: Partial<Contact>;
    outdatedContact: Contact;
  }) => {
    const fieldsToUpdate = Object.keys(dataToUpdate);

    const previousData = fieldsToUpdate.reduce((acc: Partial<Contact>, field) => {
      acc[field] = outdatedContact[field];

      return acc;
    }, {});

    let resolver: () => void;
    const promise = new Promise<void>((resolve) => {
      resolver = resolve;

      return;
    });

    const data = { previousData, promise, resolver };

    this.requestsMap[contactId] = data;
  };

  clearRequest = (contactId: string) => {
    this.requestsMap[contactId] = undefined;
  };

  getRequestByContactId = (contactId: string) => {
    return this.requestsMap[contactId];
  };
}

const patchRequestsManager = new PatchRequestsManager();

export const patchManyContacts = createAsyncThunk(
  'contacts/patchManyContacts',
  async (
    entities: Array<{ uuid: Contact['uuid']; updated_at: number } & Partial<Contact>>,
    { getState }
  ) => {
    const state = getState() as AppState;
    const currentWorkspaceId = selectCurrentWorkspaceId(state);

    const failed = await patchManyContactsAPI({ entities, workspaceId: currentWorkspaceId });

    return failed;
  }
);

export const patchContactOptimistic = createAsyncThunk(
  'contacts/patchContactOptimistic',
  async (
    {
      contactId,
      data,
    }: {
      contactId: string;
      data: Partial<Contact> & { updated_at: number };
    },
    { dispatch, getState }
  ) => {
    const outdatedContact = selectContactsMap(getState() as AppState)[contactId];

    dispatch(actions.patchContact({ contactId, data }));

    const prevRequest = patchRequestsManager.getRequestByContactId(contactId);

    if (prevRequest) {
      await prevRequest.promise;
    }

    patchRequestsManager.addRequest({
      contactId,
      dataToUpdate: data,
      outdatedContact,
    });

    const workspaceId = Object.prototype.hasOwnProperty.call(data, 'workspace_id')
      ? data.workspace_id
      : outdatedContact.workspace_id;
    await patchContactAPI({ uuid: contactId, data, workspaceId });

    return data;
  }
);

// works in optimistic mode
export const createContact = createAsyncThunk(
  'contacts/createContact',
  async (params: Partial<Contact>, { getState, dispatch }) => {
    const state = getState() as AppState;
    const currentWorkspaceId = selectCurrentWorkspaceId(state);
    const { email } = selectCurrentUserMemberData(state);

    const contacts = selectContactsArray(state);

    const anchorIds = findAnchors(contacts, params as Contact).map(({ uuid }) => uuid);

    const contact = {
      ...params,
      assigned_to: email,
      workspace_id: currentWorkspaceId,
      anchor_contact_ids: anchorIds.length === 0 ? null : anchorIds,
    } as Contact;

    dispatch(actions.addContact(contact));

    await createContactAPI(omit(contact, 'anchor_contact_ids') as Contact);

    return contact;
  }
);

export const deleteContact = createAsyncThunk(
  'contacts/deleteContact',
  async ({
    contactId,
    workspaceId,
  }: {
    contactId: Contact['uuid'];
    workspaceId: Contact['workspace_id'];
  }) => {
    await deleteContactAPI({ uuid: contactId, workspaceId });
    return contactId;
  }
);

interface InitialState {
  loading: 'idle' | 'pending';
  contactsArray: Contact[];
  currentContactId: string | null;
}

const initialState: InitialState = {
  loading: 'idle',
  contactsArray: [],
  currentContactId: null,
};
const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setContacts(state, action: PayloadAction<Contact[]>) {
      state.contactsArray = action.payload;
    },
    addContact(state, action: PayloadAction<Contact>) {
      state.contactsArray.push(action.payload);
    },
    patchContact(state, action: PayloadAction<{ contactId: string; data: Partial<Contact> }>) {
      const { contactId, data } = action.payload;

      const index = state.contactsArray.findIndex((contactItem) => contactItem.uuid === contactId);
      const contact = state.contactsArray[index];

      const updatedContact = {
        ...contact,
        ...data,
      };

      state.contactsArray.splice(index, 1, updatedContact);
    },
    updateContact(state, action) {
      const currentContactIndex = state.contactsArray.findIndex(
        (contact) => contact.uuid == action.payload.uuid
      );
      state.contactsArray[currentContactIndex] = action.payload;
    },
    setCurrentContact(state, action) {
      state.currentContactId = action.payload;
    },
    setField(state, action) {
      find(state.contactsArray, ({ uuid }) => uuid == state.currentContactId)[action.payload.type] =
        action.payload.value;
    },
    setTag(state, action) {
      const currentContact = find(
        state.contactsArray,
        ({ uuid }) => uuid == action.payload.contactId
      );
      const existedTag = remove(currentContact.tags, (tag) => tag.name == action.payload.tag.name);
      !existedTag.length && currentContact.tags.push(action.payload.tag);
    },
    deleteTask(state, action) {
      const currentContact = find(
        state.contactsArray,
        ({ uuid }) => uuid == state.currentContactId
      );
      remove(currentContact.tasks, (task) => task.uuid == action.payload.taskId);
    },
    toggleImportantTask(state, action) {
      const currentContact = find(
        state.contactsArray,
        ({ uuid }) => uuid == state.currentContactId
      );
      const currentTask = find(currentContact.tasks, (task) => task.uuid == action.payload.taskId);
      currentTask.important = !currentTask.important;
    },
    editNote(state, action) {
      const currentContact = find(
        state.contactsArray,
        ({ uuid }) => uuid == state.currentContactId
      );
      const currentNote = find(
        currentContact.notes,
        (note) => note.uuid == action.payload.note.uuid
      );
      if (currentNote.content !== action.payload.note.content) {
        currentNote.content = action.payload.note.content;
        currentNote.updated_at = Date.now() / 1000;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteContact.fulfilled, (state: InitialState, action) => {
      //@ts-ignore
      remove(state.contactsArray, (contact) => contact.uuid === action.payload);
    });

    builder.addCase(createContact.rejected, (state, action) => {
      const index = findLastIndex(
        state.contactsArray,
        (contact) => contact.uuid == (action.meta.arg as Contact).uuid
      );

      if (index !== -1) {
        state.contactsArray.splice(index, 1);
      }
    });

    builder.addCase(patchContactOptimistic.fulfilled, (state, action) => {
      const { contactId, data } = action.meta.arg;

      const index = state.contactsArray.findIndex((contactItem) => contactItem.uuid === contactId);
      const contact = state.contactsArray[index];

      const updatedContact = {
        ...contact,
        ...data,
      };

      state.contactsArray.splice(index, 1, updatedContact);

      patchRequestsManager.clearRequest(contactId);
    });
    builder.addCase(patchContactOptimistic.rejected, (state, action) => {
      const { contactId } = action.meta.arg;

      const index = state.contactsArray.findIndex((contactItem) => contactItem.uuid === contactId);
      const contact = state.contactsArray[index];
      let data;
      const prevRequest = patchRequestsManager.getRequestByContactId(contactId);
      if (prevRequest) {
        data = {
          ...contact,
          ...prevRequest.previousData,
        };
      } else {
        data = { ...contact };
      }

      state.contactsArray.splice(index, 1, data);

      patchRequestsManager.clearRequest(contactId);
    });
    builder.addCase(patchManyContacts.fulfilled, (state, action) => {
      const entities = action.meta.arg;
      const failed = action.payload;

      const updatedEntities = entities.filter((entity) => !failed.includes(entity.uuid));

      for (const updatedEntity of updatedEntities) {
        const index = state.contactsArray.findIndex(
          (contact) => contact.uuid === updatedEntity.uuid
        );

        if (index !== -1) {
          state.contactsArray[index] = {
            ...state.contactsArray[index],
            ...updatedEntity,
          };
        }
      }
    });
  },
});

export const getPhoneNumbersMapByContactsIds = (contacts: Contact[]): Record<string, string> => {
  const mapContactsByPhoneNumber = contacts.reduce<Record<string, string>>((acc, contact) => {
    contact.phones.forEach(({ normalized_phone }) => {
      acc[normalized_phone] = contact.id;
    });
    return acc;
  }, {});
  return mapContactsByPhoneNumber;
};

const { reducer, actions } = contactsSlice;
export const {
  setContacts,
  setField,
  setTag,
  editNote,
  setCurrentContact,
  deleteTask,
  updateContact,
  toggleImportantTask,
} = actions;

export const selectContactsArray = (state: AppState) => state.contacts.contactsArray;

const selectContactsSorted = createSelector(
  selectUserLanguage,
  selectContactsArray,
  (language, contacts) => {
    const result = sortingService.getContactsSortedAlphabetically(contacts, language);
    return result;
  }
);

export const selectCurrentContact = (state: AppState) => {
  if (!state.contacts.currentContactId) {
    return undefined;
  }

  const isAnchorFor = state.contacts.contactsArray.find(({ anchor_contact_ids }) => {
    if (Array.isArray(anchor_contact_ids)) {
      return anchor_contact_ids.includes(state.contacts.currentContactId);
    }
  });

  return (
    isAnchorFor ||
    state.contacts.contactsArray?.find(({ uuid }) => state.contacts.currentContactId == uuid)
  );
};
export const selectRelatedContactsByPhones = (phones: string[]) =>
  createSelector(selectContactsSorted, (contacts: Contact[]) =>
    contacts.filter((contact: Contact) =>
      contact.phones?.some((phone) => {
        const normalizedPhone = phone?.normalized_phone ? phone.normalized_phone : '';

        return !!normalizedPhone && phones?.includes(phone.normalized_phone);
      })
    )
  );

export const selectRelatedContactsByEmails = (emails: string[]) =>
  createSelector(selectContactsSorted, (contacts: Contact[]) =>
    contacts.filter((contact: Contact) =>
      contact.emails?.some((email) => {
        const emailResult = email?.email ? email.email : '';

        return !!emailResult && emails?.includes(emailResult);
      })
    )
  );

export const selectContactTags = (state: AppState) => state.contacts.contactsArray;

export const filterAssignedOrPersonalContacts = (
  contacts: Contact[],
  assignedTo: string
): Contact[] => {
  return contacts.filter((contactItem) => {
    const isAssigned = contactItem.workspace_id !== null && contactItem.assigned_to === assignedTo;
    const isPersonal = contactItem.workspace_id === null;

    return isAssigned || isPersonal;
  }) as Contact[];
};

export const selectContacts = createSelector(selectContactsSorted, (contacts: Contact[]) => {
  const contactsMap = new Map();
  contacts &&
    contacts.forEach((contact) => {
      contactsMap.set(contact.uuid, contact);
    });
  return Object.fromEntries(contactsMap);
});

export const selectContactsMap = createSelector(selectContactsArray, (contactsArray) => {
  return (contactsArray || ([] as Contact[])).reduce(function (map, contact) {
    map[contact.uuid] = contact;
    return map;
  }, {}) as Record<string, Contact>;
});

export const makeSelectContactByUuid = () =>
  createSelector(
    selectContactsSorted,
    (_, uuid: string) => uuid,
    (contactsArray, uuid) => contactsArray.find((contact) => contact.uuid === uuid)
  );

export const makeSelectContactByNormalizedPhone = () =>
  createSelector(
    selectContactsSorted,
    (_, normalized_phone: string) => normalized_phone,
    (contactsArray, normalized_phone) =>
      filterByAnchors(contactsArray).find((contact) =>
        contact.phones.some((phone) => phone.normalized_phone === normalized_phone)
      ) as Contact
  );

export const selectNotHiddenContacts = createSelector(
  selectContactsSorted,
  (contactsArray: Contact[]) => contactsArray.filter((contact) => !contact.not_show)
);

export const selectArchivedContacts = createSelector(
  selectNotHiddenContacts,
  (contactsArray: Contact[]) => contactsArray.filter((contact) => !!contact.is_archived)
);
export const selectContactsWithoutArchived = createSelector(
  selectNotHiddenContacts,
  (contactsArray: Contact[]) => {
    const anchorsToHide = [];

    return contactsArray
      .filter((contact) => {
        if (contact.is_archived && Array.isArray(contact.anchor_contact_ids)) {
          return anchorsToHide.push(...contact.anchor_contact_ids);
        }

        return !contact.is_archived;
      })
      .filter(({ uuid }) => !anchorsToHide.includes(uuid));
  }
);

export const selectHiddenContacts = createSelector(
  selectContactsSorted,
  (contactsArray: Contact[]) => contactsArray.filter((contact) => !!contact.not_show)
);

export default reducer;
