import { AxiosError } from 'axios';
import { BehaviorSubject, catchError, map, tap, throwError } from 'rxjs';
import { injectable, inject } from 'inversify';

import { IPaymentProviderStateRepository } from '../domain/abstractions/repositories/IPaymentProviderStateRepository';
import { IPaymentProviderApiService } from './network/PaymentProviderApiService';
import { IBillingDetailsStateRepository } from '../domain/abstractions/repositories/IBillingDetailsStateRepository';
import { TYPES } from '../di/types';

@injectable()
export default class PaymentProviderStateRepository implements IPaymentProviderStateRepository {
  @inject(TYPES.PaymentProviderApiService) private api: IPaymentProviderApiService;
  @inject(TYPES.BillingDetailsStateRepository)
  private billingDetailsStateRepository: IBillingDetailsStateRepository;
  private pfTokenSubject = new BehaviorSubject<string>(null);

  getPFToken() {
    if (this.pfTokenSubject.value === null) {
      this.updatePFToken();
    }
    return this.pfTokenSubject;
  }

  updatePFToken() {
    return this.api.generatePFToken().subscribe((token) => {
      this.pfTokenSubject.next(token);
    });
  }

  bindPFTokenToUser(token, last4Digits) {
    return this.api.bindPFTokenToUser(token, last4Digits).pipe(
      tap((bluesnapPaymentSources) => {
        // update credit cards info in the billing details
        this.billingDetailsStateRepository.updatePaymentSources(bluesnapPaymentSources);
        this.updatePFToken();
      }),
      map(() => true),
      catchError((err: AxiosError) =>
        throwError(() => ({
          status: err.response.status,
          data: err.response.data,
        }))
      )
    );
  }
}
