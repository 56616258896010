import { createSlice, PayloadAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { AppState } from '../app/store/store';
import { addTeammembersAPI, removeTeammembersAPI, DeleteTeammates } from '../api/workspacesAPI';
import { updateWorkspaceAPI, patchWorkspaceAPI } from '../api/workspacesAPI';
import PatchRequestsManager from '../utils/patchRequestsManager';

import remove from 'lodash/remove';
import { selectUserProfile } from './userSlice';
import { selectContactsArray } from './contactsSlice';
import Contact from 'types/contact';
import { getDataFromLocalStorage } from 'src/utils';

import WorkspaceEntity, { TeamMemberEntity } from '../db/entities/workspace/WorkspaceEntity';
import ProfileEntity from '../db/entities/profile/ProfileEntity';

const sliceName = 'workspaces';

interface SliceState {
  list: WorkspaceEntity[];
  current: WorkspaceEntity['id'] | null;
}

const getInitialState = (): SliceState => ({
  list: [],
  current: getDataFromLocalStorage('current_workspace_id') || null,
});

export const updateWorkspace = createAsyncThunk<
  WorkspaceEntity,
  WorkspaceEntity,
  { state: AppState }
>(`${sliceName}/updateWorkspace`, async (payload) => {
  const updated = { ...payload, updatedAt: Date.now() };

  await updateWorkspaceAPI(updated);

  return updated;
});

const patchRequestsManager = new PatchRequestsManager<WorkspaceEntity>();

export const patchWorkspaceOptimistic = createAsyncThunk(
  `${sliceName}/patchWorkspaceOptimistic`,
  async (
    { id, data }: { id: string; data: Partial<WorkspaceEntity> & { updatedAt: number } },
    { getState, dispatch, requestId }
  ) => {
    const state = getState() as AppState;
    const outdatedEntity = selectWorkspaces(state).find((wpItem) => wpItem.id === id);

    patchRequestsManager.enqueueRequest({
      entityId: id,
      requestId: requestId,
      updatedData: data,
      outdatedEntity,
    });

    dispatch(actions.patchWorkspace({ id, data }));

    await patchRequestsManager.wait({ entityId: id, requestId });

    const requestAborted = !patchRequestsManager.getRequestById({ entityId: id, requestId });
    if (requestAborted) {
      return false;
    } else {
      await patchWorkspaceAPI(id, data);

      return { id, data };
    }
  }
);

export const deleteTeammates = createAsyncThunk<
  DeleteTeammates[],
  DeleteTeammates[],
  { state: AppState }
>(`${sliceName}/deleteTeammates`, async (payload, { getState, rejectWithValue }) => {
  try {
    const state = getState();
    const currentWorkspaceId = state.workspaces.current;

    await removeTeammembersAPI(payload, currentWorkspaceId);
    return payload;
  } catch (error) {
    return rejectWithValue({
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data,
    });
  }
});

export const updateTeammates = createAsyncThunk<
  WorkspaceEntity,
  WorkspaceEntity['members'],
  { state: AppState }
  //@ts-ignore
>(`${sliceName}/fetchAddMembers`, async (payload, { getState, rejectWithValue }) => {
  try {
    const state = getState();
    const currentWorkspaceId = state.workspaces.current;
    const data = await addTeammembersAPI({ members: payload, workspaceId: currentWorkspaceId });
    return data;
  } catch (error) {
    return rejectWithValue({
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data,
    });
  }
});

export const resendInvite = createAsyncThunk<void, TeamMemberEntity['email'], { state: AppState }>(
  `${sliceName}/resendInvite`,
  async (email, { getState }) => {
    const state = getState();
    const currentWorkspaceId = state.workspaces.current;
    const { members } = state.workspaces.list.find(
      (workspace) => workspace.id === state.workspaces.current
    );
    const resendToMember = members.find((member) => member.email === email);

    await addTeammembersAPI({
      members: [resendToMember],
      workspaceId: currentWorkspaceId,
      resend: true,
    });
  }
);

const workspacesSlice = createSlice({
  name: sliceName,
  initialState: getInitialState,
  reducers: {
    patchWorkspace(state, action: PayloadAction<{ id: string; data: Partial<WorkspaceEntity> }>) {
      const { id, data } = action.payload;

      const index = state.list.findIndex((taskItem) => taskItem.id === id);
      const task = state.list[index];

      const updatedWp = {
        ...task,
        ...data,
      };

      state.list.splice(index, 1, updatedWp);
    },
    chooseWorkspace: (state, action: PayloadAction<WorkspaceEntity['id'] | null>) => {
      state.current = action.payload;
    },
    setWorkspaces: (state, action: PayloadAction<WorkspaceEntity[]>) => {
      state.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateWorkspace.fulfilled, (state, action) => {
      const updatedWorkspace = action.payload;
      const index = state.list.findIndex((workspace) => workspace.id == updatedWorkspace.id);
      state.list[index] = updatedWorkspace;
    });
    builder.addCase(updateTeammates.fulfilled, (state, action) => {
      const currentWorkspaceIndex = state.list.findIndex(
        (workspace) => workspace.id == state.current
      );
      state.list[currentWorkspaceIndex] = {
        ...state.list[currentWorkspaceIndex],
        ...action.payload,
      };
    });
    builder.addCase(deleteTeammates.fulfilled, (state, action) => {
      const currentWorkspaceIndex = state.list.findIndex(
        (workspace) => workspace.id == state.current
      );
      action.payload.map((removedTeammate) => {
        remove(
          state.list[currentWorkspaceIndex].members,
          (member) => member.email == removedTeammate.email
        );
      });
    });

    builder.addCase(patchWorkspaceOptimistic.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;

      const aborted = action.payload === false;

      if (!aborted) {
        patchRequestsManager.dequeueRequest({ entityId: arg.id, requestId });
      }
    });
    builder.addCase(patchWorkspaceOptimistic.rejected, (state, action) => {
      const { arg, requestId } = action.meta;

      const { outdatedEntity } = patchRequestsManager.getRequestById({
        entityId: arg.id,
        requestId,
      });
      const index = state.list.findIndex((taskItem) => taskItem.id === arg.id);

      if (index !== -1) {
        const task = state.list[index];

        const data = {
          ...task,
          ...outdatedEntity,
        };

        state.list.splice(index, 1, data);
      }

      patchRequestsManager.dequeueRequest({ entityId: arg.id, requestId });
      patchRequestsManager.clearAllRequestsForEntity(arg.id);
    });
  },
});

export const { actions, reducer } = workspacesSlice;

export const selectCurrentWorkspaceId = (state: AppState) => state.workspaces.current;
export const selectWorkspaces = (state: AppState) => state.workspaces.list;

export const selectAcceptedTeamMembers = (state: AppState) => {
  const currentWorkspace = state.workspaces.list.find(
    (workspaceItem) => workspaceItem.id === state.workspaces.current
  ) as WorkspaceEntity;

  return currentWorkspace?.members.filter((member) => member.invitationStatus === 'accepted');
};
export const selectCurrentWorkspace = (state: AppState) => {
  const currentWorkspace = state.workspaces.list.find(
    (workspaceItem) => workspaceItem.id === state.workspaces.current
  ) as WorkspaceEntity;
  return currentWorkspace;
};

export const selectProposalsColNextId = createSelector(
  selectCurrentWorkspace,
  (wp: WorkspaceEntity) => wp.proposalsNextColId
);

export function selectIsAdminOrOwner(state: AppState) {
  const currentWorkspace = selectCurrentWorkspace(state);
  const userProfile = selectUserProfile(state);

  if (!currentWorkspace) {
    return false;
  }

  const member = currentWorkspace.members.find(({ email }) => email === userProfile?.email);

  return member?.role === 'admin' || member?.role === 'owner';
}

export const selectIsCurrentWorkspaceCollaborative = (state: AppState) => {
  const currentWorkspace = selectCurrentWorkspace(state);

  if (!currentWorkspace) {
    return false;
  }

  return isWorkspaceCollaborative(currentWorkspace);
};

export const isWorkspaceCollaborative = (workspace: WorkspaceEntity) => {
  return workspace?.members.filter((member) => member.invitationStatus === 'accepted').length > 1;
};

export const selectCurrentUserMemberData = (state: AppState) => {
  const currentWorkspace = selectCurrentWorkspace(state);
  const userProfile = selectUserProfile(state);

  const member = currentWorkspace?.members?.find(({ email }) => email === userProfile?.email);

  return member;
};

export const selectMemberDataByEmail = (email: string) => (state: AppState) => {
  const currentWorkspace = selectCurrentWorkspace(state);
  const member = currentWorkspace.members.find((member) => member.email === email);

  return member;
};

export const selectEnrichedWorkspaces = createSelector(
  selectWorkspaces,
  selectUserProfile,
  (workspaces: WorkspaceEntity[], userProfile: ProfileEntity) => {
    const personalIndex = workspaces.findIndex((wp) => {
      const currentUser = wp.members.find((memberItem) => memberItem?.email === userProfile?.email);

      return currentUser?.role === 'owner';
    });

    if (personalIndex === -1) {
      return workspaces;
    }

    const enrichedPersonal = {
      ...workspaces[personalIndex],
      name: workspaces[personalIndex].name || userProfile?.fullName || null,
      thumbnail: workspaces[personalIndex].thumbnail || userProfile?.picture || null,
    };

    const workspacesArrCopy = [...workspaces];
    workspacesArrCopy.splice(personalIndex, 1, enrichedPersonal);

    return workspacesArrCopy;
  }
);

export const makeSelectTeamMembersAssignedContactsMap = createSelector(
  selectAcceptedTeamMembers,
  selectContactsArray,
  (teamMembers: TeamMemberEntity[], contacts: Contact[]) => {
    if (!teamMembers) {
      return;
    }

    const map = {};

    teamMembers.forEach(({ email }) => {
      map[email] = contacts.filter((contact) => contact.assigned_to === email);
    });

    return map;
  }
);
