import React, { useContext, useRef } from 'react';
import { Container, interfaces } from 'inversify';

const InversifyContext = React.createContext<{ container: Container | null }>({ container: null });

type Props = {
  container: Container;
};

export const IocProvider: React.FC<Props> = (props) => {
  return (
    <InversifyContext.Provider value={{ container: props.container }}>
      {props.children}
    </InversifyContext.Provider>
  );
};

export function useInjection<T>(identifier: interfaces.ServiceIdentifier<T>) {
  const { container } = useContext(InversifyContext);
  if (!container) {
    throw new Error();
  }
  return container.get<T>(identifier);
}

export function useInjectionWithRef<T>(identifier: interfaces.ServiceIdentifier<T>) {
  const injected = useInjection<T>(identifier);
  const injectedRef = useRef(injected);

  return injectedRef.current;
}
