import { Observable, BehaviorSubject } from 'rxjs';
import { useState, useEffect, useRef, useCallback } from 'react';
import isEqual from 'lodash/isEqual';

export function useObservable<T>(init: () => Observable<T>, deps: ReadonlyArray<any>) {
  const [current, setCurrent] = useState(() => init());
  const savedDepsRef = useRef(deps);

  useEffect(() => {
    const depsNotEqual = !isEqual(savedDepsRef.current, deps);

    if (depsNotEqual) {
      setCurrent(init());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps]);

  return current;
}

export function useSubscription<T>(observable: Observable<T>, callback: (result: T) => void) {
  useEffect(() => {
    const subscription = observable.subscribe((result) => {
      callback(result);
    });

    return () => {
      subscription.unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observable]);
}

export function useObservableState<T>(observable: Observable<T>, initialState: T) {
  const [state, setState] = useState(initialState);

  useSubscription(observable, (result) => setState(result));

  return state;
}

export function useBehaviorSubject<T>(
  behaviorSubject: BehaviorSubject<T>
): [T, (value: T) => void] {
  const [state, setState] = useState(behaviorSubject.value);

  useEffect(() => {
    const subscription = behaviorSubject.subscribe((value) => {
      setState(value);
    });

    return () => {
      subscription.unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateState = useCallback(
    (value: T) => {
      behaviorSubject.next(value);
    },
    [behaviorSubject]
  );

  return [state, updateState];
}
